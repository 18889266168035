import confetti from 'canvas-confetti'
import analytics from './analytics'
import PLANS from '@/utils/plans'
import api from '@/utils/api'

export default async function openCheckout({
  email,
  userId,
  successCallback = () => {},
  closeCallback = () => {},
  feature,
  planType,
  affiliateName,
  coupon,
}) {
  analytics.track('Open Checkout', { feature, affiliateName, coupon })

  const planId = PLANS[planType].id

  if (affiliateName || coupon) {
    const { data } = await api.get('/billing/payment_link', {
      params: {
        planId,
        coupon,
        affiliateName,
      },
    })

    const override = data.url

    window.Paddle.Checkout.open({
      override,
      email,
      customData: {
        coupon,
      },
      successCallback: (data) => {
        showConfetti()
        successCallback(data)

        analytics.track('Checkout Completed', {
          planType,
          coupon,
          affiliateName,
        })
      },
      closeCallback: () => {
        analytics.track('Checkout Closed', {
          planType,
          coupon,
          affiliateName,
        })

        closeCallback()
      },
    })
  } else {
    window.Paddle.Checkout.open({
      product: planId,
      email,
      passthrough: userId,
      customData: {
        coupon,
      },
      successCallback: (data) => {
        analytics.track('Checkout Completed', { planType })
        showConfetti()
        successCallback(data)
      },
      closeCallback: () => {
        analytics.track('Checkout Closed', {
          planType,
          coupon,
          affiliateName,
        })

        closeCallback()
      },
    })
  }
}

export function showConfetti() {
  const end = Date.now() + 0.5 * 1000

  const colors = ['#33334f', '#6381f3']

  ;(function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors,
    })
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors,
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}
